import React from 'react';
import { Container, Grid, ListItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { groupBy } from 'lodash/fp';
import Link from 'gatsby-link';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.darkWhite,
  },
  addIcon: {
    fontSize: theme.typography.h3.fontSize,
  },
  jobs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing(8),
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.redPepper,
    },
  },
  info: {
    color: theme.palette.gray,
  },
  jobTypes: {
    color: theme.palette.gray,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  titleContainer: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(16),
  },
  title: {
    paddingTop: theme.spacing(8),
  },
  sendCV: {
    color: theme.palette.gray,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.redPepper,
    },
    marginBottom: theme.spacing(16),
  },
}));

const JobListing = ({ data }) => {
  const classes = useStyles();
  const groupedCategories = groupBy('category')(data);
  const keys = Object.keys(groupedCategories);

  return (
    <Container id="open-positions" maxWidth={false} className={classes.root}>
      <Grid container className={classes.titleContainer}>
        <Grid item>
          <Typography variant="h2" align="left" className={classes.title}>
            In which area would you like to contribute?
          </Typography>
          <Typography variant="subtitle2">Check out our open positions</Typography>
        </Grid>
      </Grid>

      <Grid container align="center" spacing={4}>
        <Grid item md={3}>
          {keys.map((item, i) => (
            <ListItem key={i} disableGutters className={classes.jobTypes}>
              <Typography variant="h5">{item}</Typography>
            </ListItem>
          ))}
        </Grid>
        <Grid item md={9}>
          {data.map((item, i) => (
            <Link key={i} to={`/careers/job${item.fields?.slug}`} className={classes.jobs} target="_blank">
              <Box display="flex" flexDirection="column">
                <Typography variant="h4" align="left">
                  {item.title}
                </Typography>
                <Typography variant="body2" className={classes.info} align="left">{` ${item.hours}`}</Typography>
              </Box>
              <AddIcon color="primary" className={classes.addIcon} />
            </Link>
          ))}

          <Typography variant="subtitle2" align="left" className={classes.title}>
            Didn’t find a position that suits you but you believe you’re a good match?
          </Typography>
          <Typography variant="body2" align="left" className={classes.sendCV}>
            Send us an email with your CV or Linkedin profile at{' '}
            <a href="mailto:jobs@spicefactory.co">jobs@spicefactory.co</a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobListing;
