import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Hidden, Link, makeStyles, ThemeProvider } from '@material-ui/core';
import perks from './perks';
import recruitment from './recruitment';
import WorkSection from '../WorkSection';
import { JumboButton } from '../JumboButton/JumboButton';
import catalonia from '../../../static/images/workations/catalonia.jpeg';
import zdrvkovac from '../../../static/images/workations/zdravkovac.jpeg';
import belgium from '../../../static/images/workations/belgium.jpeg';
import Christmas_Party from '../../../static/images/working_at_spfr/christmas_party.jpeg';
import Halloween from '../../../static/images/working_at_spfr/halloween.jpeg';
import Photoshoot from '../../../static/images/working_at_spfr/photoshoot.jpeg';
import business_run from '../../../static/images/Giving back/Business_run.jpeg';
import Child_care from '../../../static/images/Giving back/Child_care.jpeg';
import supporting_faculties from '../../../static/images/Giving back/supporting_faculties.jpeg';
import Supporting_theatre from '../../../static/images/Giving back/Supporting_theatre.jpeg';
import VDB15 from '../../../static/images/Giving back/VDB15.jpeg';
import JobListing from '../JobListing/JobListing';
import { darkTheme } from '../../theme';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),

    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(12),
    },
  },
  title: {
    marginTop: 'auto',
    fontSize: `clamp(${theme.typography.h2.fontSize}, 10vw, 10rem)`,
    lineHeight: 1,
    textTransform: 'uppercase',
    maxWidth: '55vw',
  },
  subtitle: {
    marginBottom: 'auto',
  },
  heroCover: {
    position: 'absolute',
    right: 0,
    width: '50vw',
    height: '100vh',
    zIndex: -1,
  },
  lead: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4.fontSize,
  },
  horizontalList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),

    '& > *': {
      margin: theme.spacing(2, 3),
    },
  },
  section: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
  recruitProcess: {
    display: 'block',
    margin: theme.spacing(2),
  },
  gallery: {
    columns: 2,

    '& > *': {
      filter: 'grayscale(1) brightness(1.3) contrast(.75)',
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  leadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
  },
  leadTitle: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
  perks: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
  },
  perk: {
    margin: theme.spacing(3),
    flex: 1,
    textAlign: 'center',
  },
}));

const internalLinks = [
  {
    title: 'Culture',
    href: '#culture',
  },
  {
    title: 'Process',
    href: '#process',
  },
  {
    title: 'Open Positions',
    href: '#open-positions',
  },
];

const culture = [
  {
    title: 'Join our annual workations',
    description:
      'Every year the entire team moves to a remote location for 5 days of work, fun & games. <a style="color:red;" href="https://spicefactory.co/blog/2018/10/11/spicefactory-workation-2018/">Read more about SPFR workation</a>.',
    gallery: [
      {
        src: catalonia,
        alt: '',
      },
      {
        src: zdrvkovac,
        alt: '',
      },
      {
        src: belgium,
        alt: '',
      },
    ],
  },
  {
    title: 'Working at SPFR',
    description:
      'We work either remotely or at the office. Moreover, we like to work hard whilst having lots of fun along the way.',
    gallery: [
      {
        src: Christmas_Party,
        alt: '',
      },
      {
        src: Halloween,
        alt: '',
      },
      {
        src: Photoshoot,
        alt: '',
      },
    ],
  },
  {
    title: 'Giving back',
    description:
      'Supporting various causes & giving back to the community mean the world to us. We are on a mission to support children & education, sports clubs, tech communities, culture - just to name the few. Contact us if you have a cause that might need our help.',
    gallery: [
      {
        src: business_run,
        alt: '',
      },
      {
        src: Child_care,
        alt: '',
      },
      {
        src: supporting_faculties,
        alt: '',
      },
      {
        src: Supporting_theatre,
        alt: '',
      },
      {
        src: VDB15,
        alt: '',
      },
    ],
  },
];

const Careers = ({ data }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth={false} className={classes.hero}>
        <Typography variant="h1" className={classes.title}>
          Join the CREW
        </Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>
          Working at SpiceFactory is challenging,
          <Hidden smDown>
            <br />
          </Hidden>{' '}
          but absolutely rewarding.
        </Typography>
      </Container>

      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" spacing={8}>
          <Grid item>
            <ul className={classes.horizontalList}>
              {internalLinks.map((link, i) => (
                <li key={i}>
                  <Link variant="h5" href={link.href}>
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item>
            <JumboButton color="primary" variant="contained" href="#open-positions">
              Apply now
            </JumboButton>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false}>
        <Grid container justifyContent="center">
          <Grid item className={classes.leadContainer}>
            <Typography variant="subtitle1" className={classes.leadTitle}>
              Want to make a part of innovation catalysts, problem solvers and idea generators who never fail to
              deliver? Then this is the place for you!
            </Typography>
            <JumboButton color="primary" variant="contained" href="#open-positions">
              Jump to open positions
            </JumboButton>
          </Grid>
        </Grid>
      </Container>

      <ThemeProvider theme={darkTheme}>
        <Container id="culture" maxWidth={false} className={classes.section}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="h3" gutterBottom>
                What makes SpiceFactory a great place to work?
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>
                Great success is a function of the best possible environment and even better support - which is
                something we tend to provide quite well. We believe in taking initiative and owning your career, but we
                are here to help as much as we can.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" className={classes.section}>
          <Grid container spacing={2}>
            {perks.map((item, i) => (
              <Grid item xs>
                <div key={i} className={classes.perk}>
                  <img width={150} height={115} src={item.icon} alt={item.text} />
                  <Typography align="center">{item.text}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>

      {culture.map((item, i) => (
        <Container key={i} component="section" maxWidth={false} className={classes.section}>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Typography variant="h3" gutterBottom>
                {item.title}
              </Typography>
              <Typography dangerouslySetInnerHTML={{ __html: item.description }} />
            </Grid>
            <Grid item md={7}>
              <div className={classes.gallery}>
                {item.gallery.map((image, i) => (
                  <img key={i} src={image.src} alt={image.alt || 'SPFR Culture'} />
                ))}
              </div>
            </Grid>
          </Grid>
        </Container>
      ))}

      <ThemeProvider theme={darkTheme}>
        <WorkSection />
      </ThemeProvider>

      <Container id="process" maxWidth={false} className={classes.section}>
        <Grid container justifyContent="center">
          <Grid item md={8}>
            <Typography align="center" variant="h2" gutterBottom>
              Our Recruitment Process
            </Typography>
            <Typography align="center">
              Want to make a part of innovation catalysts, problem solvers and idea generators who never fail to
              deliver? Then this is the place for you!
            </Typography>
          </Grid>
        </Grid>
        <Box mt={6}>
          <div className={classes.perks}>
            {recruitment.map((item, i) => (
              <div key={i} className={classes.perk}>
                <img width={120} height={110} src={item.icon} alt={item.title} />
                <Typography variant="h5" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body2">{item.text}</Typography>
              </div>
            ))}
          </div>
        </Box>
      </Container>
      {data ? <JobListing data={data} /> : null}
    </Fragment>
  );
};

export default Careers;
