import review from '../../../static/images/recruitment/review.png';
import getToKnow from '../../../static/images/recruitment/get_to_know.png';
import assignment from '../../../static/images/recruitment/assignment.png';
import finalInterview from '../../../static/images/recruitment/final_interviews.png';
import offer from '../../../static/images/recruitment/offer.png';

const recruitment = [
  {
    icon: review,
    title: 'Review',
    text: 'It’s quick & humans are involved.',
  },
  {
    icon: getToKnow,
    title: 'Let’s get to know each other',
    text: 'In this ~30 mins session we’d like to know more about you.',
  },
  {
    icon: assignment,
    title: 'The assignment',
    text: 'Show off your tech & problem-solving skills with a simple assignment. ',
  },
  {
    icon: finalInterview,
    title: 'Final interviews',
    text: 'The final ~30 mins session with senior management and/or CEO to learn if we’re a cultural fit.',
  },
  {
    icon: offer,
    title: 'Offer',
    text: 'As the last step, the offer may be on your way shortly.',
  },
];

export default recruitment;
