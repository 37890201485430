import React from 'react';
import Layout from '../layout';
import Careers from '../components/Careers/Careers';
import JsxSeo from '../components/SEO/JsxSEO';
import { graphql } from 'gatsby';

function Career({ location, data }) {
  return (
    <Layout>
      <JsxSeo
        title="Careers"
        description="Join our seasoned team of pros and work on the most exciting projects ever I We’re hiring"
        location={location}
      />
      <Careers data={data.allFirestoreJobs.nodes.filter(_ => !_.isArchived)} />
    </Layout>
  );
}

export default Career;

export const firestoreJobsQuery = graphql`
  query FireStoreJobs {
    allFirestoreJobs {
      nodes {
        category
        description
        fields {
          slug
        }
        id
        title
        hours
        isArchived
      }
    }
  }
`;
