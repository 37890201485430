import world from '../../../static/images/perks/native_remote.png';
import reward from '../../../static/images/perks/get_rewarded.png';
import time from '../../../static/images/perks/flexible_hours.png';
import mac from '../../../static/images/perks/top_notch_equipment.png';
import project from '../../../static/images/perks/cool_projects.png';
import asset from '../../../static/images/perks/career_development.png';
import mic from '../../../static/images/perks/conferences.png';
import workation from '../../../static/images/perks/workations.png';
import decision from '../../../static/images/perks/decision_making.png';
import balance from '../../../static/images/perks/work_life_balance.png';

const perks = [
  {
    icon: world,
    text: 'Native remote, work from anywhere in the world',
  },
  {
    icon: reward,
    text: 'Get extra rewarded for your hard work',
  },
  {
    icon: time,
    text: 'Flexible working hours',
  },
  {
    icon: mac,
    text: 'Top-notch equipment like MacBookPro & home office stipend',
  },
  {
    icon: project,
    text: 'Work on super cool & interesting projects',
  },
  {
    icon: asset,
    text: 'Career development',
  },
  {
    icon: mic,
    text: 'Speak at or attend conferences you like, it’s on us!',
  },
  {
    icon: workation,
    text: 'Epic workations & company retreats',
  },
  {
    icon: decision,
    text: 'Take part in decision making',
  },
  {
    icon: balance,
    text: 'Work-life balance guaranteed',
  },
];

export default perks;
